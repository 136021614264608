import { Inject, Injectable } from '@angular/core';
import { AuthorizationService } from '@services/authorization.service';
import { AuthenticationRepository } from '@repository/authentication/authentication.repository';
import { CentralServerService } from '@services/central-server.service';
import { AuthService } from '@auth0/auth0-angular';
import { Observable, retry, shareReplay, Subscription, take, tap, throwError, timeout } from 'rxjs';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { ConnectionError, SSOError } from '@repository/authentication/models/errors.model';
import { TenantRepository } from '@repository/tenant/tenant.repository';
import { LoginResponse } from '../../../../types/DataResult';
import { LoginServiceState, LoginState } from '../login-state.model';
import { LOGIN_STATE_TOKEN } from '../login-state.provider';
import { TenantSSOOptions } from '../../../../types/login/SSO';

@Injectable()
export class SSOLoginBusinessService {
  private static readonly TIMEOUT_MS = 5000;
  public tenantSSOOptions$: Observable<TenantSSOOptions>;
  public constructor(
    private readonly authorizationService: AuthorizationService,
    private readonly authenticationRepository: AuthenticationRepository,
    private readonly tenantRepository: TenantRepository,
    private readonly centralServerService: CentralServerService,
    private readonly authService: AuthService,
    @Inject(LOGIN_STATE_TOKEN) public loginState: LoginState,
  ) {
    this.tenantSSOOptions$ = this.getTenantSSOOptions();
  }

  public ssoLogin(idToken: string): Observable<LoginResponse> {
    this.authorizationService.cleanUserAndUserAuthorization();
    return this.authenticationRepository.ssoLogin(idToken).pipe(timeout(SSOLoginBusinessService.TIMEOUT_MS));
  }

  public handleAuthorizationCallback(): Observable<LoginResponse> {
    this.loginState.state.set(LoginServiceState.LOADING);

    return this.getIdToken().pipe(
      switchMap((idToken) => this.ssoLogin(idToken)),
      tap((result) => {
        this.centralServerService.loginSucceeded(result.token, result.payload);
        this.loginState.state.set(LoginServiceState.SUCCESS);
      }),
      catchError((error) => {
        this.handleError(error);
        return throwError(() => error);
      }),
    );
  }

  public subscribeAuth0Errors(): void {
    this.authService.error$.pipe(take(1)).subscribe({
      next: (error) => {
        this.handleError(new SSOError({ cause: error }));
      },
    });
  }

  public getIdToken(): Observable<string> {
    return this.authService.idTokenClaims$.pipe(
      filter((idToken) => idToken !== null),
      take(1),
      map((idToken) => idToken.__raw),
      timeout(SSOLoginBusinessService.TIMEOUT_MS),
    );
  }

  public getTenantSSOOptions(): Observable<TenantSSOOptions> {
    return this.tenantRepository
      .getTenantSSOOptions()
      .pipe(timeout(SSOLoginBusinessService.TIMEOUT_MS), retry({ count: 5, delay: 2000 }), shareReplay(1));
  }

  public loginWithSSO(): Observable<void> {
    this.loginState.state.set(LoginServiceState.LOADING);

    return this.tenantSSOOptions$.pipe(
      switchMap((options) => {
        return this.authService.loginWithRedirect({
          authorizationParams: {
            organization: options.organization,
            prompt: 'login',
          },
        });
      }),
      catchError((error) => {
        // Handle the error from the ssoOptions call
        const errorWrapper = new ConnectionError({ cause: error });
        this.handleError(errorWrapper);
        return throwError(() => errorWrapper);
      }),
    );
  }

  public loginWithSSOAndSubscribe(): Subscription {
    return this.loginWithSSO().subscribe();
  }

  private handleError(error: any): void {
    this.loginState.state.set(LoginServiceState.ERROR);
    this.loginState.error.set(error);
  }
}
