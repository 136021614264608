import { Component, effect, Inject } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Users } from '@utils/Users';
import { MessageService } from '@services/message.service';
import { DialogService } from '@services/dialog.service';
import { AccountPendingError } from '@repository/authentication/models/errors.model';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { ButtonAction } from '../../../../../types/GlobalType';
import { LoginBusinessService } from '../../business-service/login-business.service';
import { LOGIN_STATE_TOKEN } from '../../login-state.provider';
import { LoginServiceState, LoginState } from '../../login-state.model';

@Component({
  selector: 'app-login-page-form',
  templateUrl: './login-page-form.component.html',
  styleUrl: './login-page-form.component.scss',
})
export class LoginPageFormComponent {
  public loginForm = this.formBuilder.group({
    email: ['', [Validators.required, Validators.email]],
    password: ['', [Validators.required, Users.passwordWithNoSpace]],
  });

  public constructor(
    private formBuilder: FormBuilder,
    private loginBusinessService: LoginBusinessService,
    public messageService: MessageService,
    public dialogService: DialogService,
    public translateService: TranslateService,
    public router: Router,
    @Inject(LOGIN_STATE_TOKEN) public loginState: LoginState,
  ) {
    this.initFormWithEmail();
    this.setOnAccountPendingErrorHandler();
  }

  private initFormWithEmail(): void {
    effect(() => {
      this.loginForm.controls.email.setValue(this.loginState.initialEmail());
    });
  }

  public onLogin(): void {
    if (this.loginForm.invalid) {
      // Mark all fields as dirty to show the error messages
      Object.values(this.loginForm.controls).forEach((control) => control.markAsDirty());
      return;
    }

    this.loginBusinessService.login(this.loginForm.getRawValue());
  }

  public setOnAccountPendingErrorHandler(): void {
    effect(() => {
      if (this.loginState.state() !== LoginServiceState.ERROR) {
        return;
      }
      const error = this.loginState.error();
      if (error instanceof AccountPendingError) {
        this.handleAccountPendingError();
      }
    });
  }

  private handleAccountPendingError(): void {
    this.messageService.showWarningMessage('authentication.account_pending');
    this.dialogService
      .createAndShowYesNoDialog(
        this.translateService.instant('authentication.verify_email_title'),
        this.translateService.instant('authentication.verify_email_resend_confirm'),
      )
      .subscribe((response) => {
        if (response === ButtonAction.YES) {
          void this.router.navigate(['/auth/verify-email'], {
            queryParams: { Email: this.loginForm.controls.email.value },
          });
        }
      });
  }
}
