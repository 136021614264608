import { Injectable } from '@angular/core';
import { AuthenticationApiService } from '@api/authentication/authentication.api.service';
import { WindowService } from '@services/window.service';
import { Observable, ObservableInput } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { StatusCodes } from 'http-status-codes';
import {
  AccountAlreadyActive,
  AccountInactiveError,
  AccountLockedError,
  AccountPendingError,
  AccountSuspendedError,
  ConnectionError,
  EmailNotValidError,
  ErrorWhileCreatingUserError,
  EulaNotAcceptedError,
  SSOAccountNotFound,
  SSOLoginError,
  SuperAccountPendingError,
  TechnicalUserCannotLoginToUIError,
  TokenNotValidError,
  UserEmailAlreadyExistError,
  WrongEmailOrPasswordError,
} from '@repository/authentication/models/errors.model';
import { RegisterUserModel } from '@repository/authentication/models/register-user.model';
import { ResetPasswordModel } from '@repository/authentication/models/reset-password.model';
import { RestResponse } from '../../types/GlobalType';
import { HTTPError } from '../../types/HTTPError';
import { ActionResponse, LoginResponse, VerifyEmailResponse } from '../../types/DataResult';

@Injectable()
export class AuthenticationRepository {
  public constructor(
    private readonly authenticationApi: AuthenticationApiService,
    public windowService: WindowService,
  ) {}

  public login(email: string, password: string, acceptEula: boolean): Observable<LoginResponse> {
    const tenant = this.windowService.getSubdomain();
    return this.handleLoginResponse(this.authenticationApi.login(email, password, acceptEula, tenant), tenant);
  }

  public ssoLogin(idToken: string): Observable<LoginResponse> {
    const tenant = this.windowService.getSubdomain();
    return this.handleLoginResponse(this.authenticationApi.ssoLogin(tenant, idToken), tenant);
  }

  public register(user: RegisterUserModel): Observable<void> {
    return this.authenticationApi.register(user).pipe(
      catchError((error) => {
        if (error.status === HTTPError.ERROR_510) {
          throw new UserEmailAlreadyExistError();
        }

        if (error.status === HTTPError.USER_EULA_ERROR) {
          throw new EulaNotAcceptedError();
        }

        throw new ErrorWhileCreatingUserError();
      }),
      map((response) => {
        if (response.status !== RestResponse.SUCCESS) {
          throw new ErrorWhileCreatingUserError();
        }
      }),
    );
  }

  public resetPassword(data: ResetPasswordModel): Observable<ActionResponse> {
    return this.authenticationApi.resetPassword(data);
  }

  public definePassword(password: string, hash: string): Observable<void> {
    const tenant = this.windowService.getSubdomain();
    return this.authenticationApi.definePassword(password, hash, tenant).pipe(
      map((response) => {
        if (response.status !== RestResponse.SUCCESS) {
          throw new ErrorWhileCreatingUserError();
        }
      }),
    );
  }

  public verifyEmail(email: string, verificationToken: string): Observable<VerifyEmailResponse> {
    const tenant = this.windowService.getSubdomain();
    return this.authenticationApi
      .verifyEmail({ Email: email, VerificationToken: verificationToken, Tenant: tenant })
      .pipe(
        catchError(this.handleVerificationEmailError),
        map((response) => {
          if (response.status !== RestResponse.SUCCESS) {
            throw new Error('Error while verifying email');
          }

          return response;
        }),
      );
  }

  public resendVerificationEmail(captcha: string, email: string): Observable<ActionResponse> {
    const tenant = this.windowService.getSubdomain();
    return this.authenticationApi.resendVerificationEmail({ captcha, email, tenant }).pipe(
      catchError(this.handleVerificationEmailError),
      map((response) => {
        if (response.status !== RestResponse.SUCCESS) {
          throw new Error('Error while resending verification email');
        }

        return response;
      }),
    );
  }

  private handleVerificationEmailError(error: any): ObservableInput<any> {
    switch (error.status) {
      case HTTPError.ALREADY_EXISTS_ERROR:
        throw new AccountAlreadyActive();
      case HTTPError.ERROR_540:
        throw new TokenNotValidError();
      case StatusCodes.NOT_FOUND:
        throw new EmailNotValidError();
      default:
        throw new Error('Error while verifying email');
    }
  }

  private handleLoginResponse(response: Observable<LoginResponse>, tenant: string): Observable<LoginResponse> {
    return response.pipe(
      catchError((error) => {
        switch (error.status) {
          case StatusCodes.NOT_FOUND:
            throw new WrongEmailOrPasswordError();
          // Account is locked
          case HTTPError.USER_ACCOUNT_LOCKED_ERROR:
            throw new AccountLockedError();
          // Account is inactive
          case HTTPError.ERROR_594:
            throw new AccountInactiveError();
          // Account Suspended
          case HTTPError.ERROR_593:
            throw new AccountSuspendedError();
          case HTTPError.ERROR_0:
            throw new ConnectionError({ cause: error });
          // API User
          case HTTPError.TECHNICAL_USER_CANNOT_LOG_TO_UI_ERROR:
            throw new TechnicalUserCannotLoginToUIError();
          case HTTPError.USER_ACCOUNT_PENDING_ERROR:
            if (!tenant) {
              throw new SuperAccountPendingError();
            } else {
              throw new AccountPendingError();
            }
          case HTTPError.SSO_USER_NOT_FOUND:
            throw new SSOAccountNotFound();
          case HTTPError.SSO_LOGIN_FAILED:
            throw new SSOLoginError();
        }
        throw error;
      }),
    );
  }
}
