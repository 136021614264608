import { Inject, Injectable } from '@angular/core';
import { AuthorizationService } from '@services/authorization.service';
import { AuthenticationRepository } from '@repository/authentication/authentication.repository';
import { CentralServerService } from '@services/central-server.service';
import { ActivatedRoute } from '@angular/router';
import { LOGIN_STATE_TOKEN } from '../login-state.provider';
import { LoginServiceState, LoginState } from '../login-state.model';

@Injectable()
export class LoginBusinessService {
  private static readonly DEMO_EMAIL = 'demo.demo@vev.com';

  public constructor(
    private readonly authorizationService: AuthorizationService,
    private readonly authenticationRepository: AuthenticationRepository,
    private readonly centralServerService: CentralServerService,
    private route: ActivatedRoute,
    @Inject(LOGIN_STATE_TOKEN) public loginState: LoginState,
  ) {}

  public init(): void {
    this.loginState.redirectUrl.set(this.route.snapshot.queryParams['returnUrl'] || '/');
    if (this.route.snapshot.fragment) {
      this.loginState.redirectUrl.set(this.loginState.redirectUrl() + `#${this.route.snapshot.fragment}`);
    }

    const email = this.route.snapshot.queryParamMap.get('email');
    const password = this.route.snapshot.queryParamMap.get('password');
    if (email === LoginBusinessService.DEMO_EMAIL && password) {
      this.login({ email, password });
    }

    if (email) {
      this.loginState.initialEmail.set(email);
    }
  }

  public login({ email, password }: { email: string; password: string }): void {
    this.loginState.state.set(LoginServiceState.LOADING);
    this.authorizationService.cleanUserAndUserAuthorization();

    this.authenticationRepository.login(email, password, true).subscribe({
      next: (result) => {
        this.centralServerService.loginSucceeded(result.token, result.payload);
        this.loginState.state.set(LoginServiceState.SUCCESS);
      },
      error: (error) => {
        this.loginState.state.set(LoginServiceState.ERROR);
        this.loginState.error.set(error);
      },
    });
  }
}
