<div class="login-page-form">
  <app-tenant-logo class="login-page-form__tenant-logo"></app-tenant-logo>
  <div class="login-page-form__title">
    <h1>{{ 'authentication.welcome' | translate }}</h1>
    <p class="h3">{{ 'authentication.subtitle' | translate }}</p>
  </div>
  <form
    class="login-page-form__form-group"
    [formGroup]="loginForm"
    (ngSubmit)="onLogin()"
  >
    <app-input
      placeholder="{{ 'authentication.email' | translate }}"
      formName="email"
      autocomplete="email"
      [errors]="{ email: 'authentication.invalid_email' }"
    ></app-input>

    <app-input
      placeholder="{{ 'authentication.password' | translate }}"
      formName="password"
      type="password"
      autocomplete="current-password"
      [errors]="{ noSpace: 'authentication.no_space_in_password' }"
    ></app-input>

    <button
      mat-raised-button
      color="primary"
      type="submit"
    >
      {{ 'authentication.sign_in' | translate }}
    </button>
  </form>
  <a
    routerLink="/auth/reset-password"
    class="login-page-form__forgot-password"
  >
    {{ 'authentication.forgot_password' | translate }}
  </a>
  <app-sso-login-button></app-sso-login-button>
</div>
