import { WritableSignal } from '@angular/core';

export interface LoginState {
  state: WritableSignal<string>;
  redirectUrl: WritableSignal<string>;
  initialEmail: WritableSignal<string | undefined>;
  error: WritableSignal<Error | undefined>;
}

export enum LoginServiceState {
  IDLE = 'IDLE',
  LOADING = 'LOADING',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
}
