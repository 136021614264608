import { InjectionToken, signal } from '@angular/core';
import { LoginServiceState, LoginState } from './login-state.model';

export const createLoginState = (): LoginState => {
  return {
    state: signal(LoginServiceState.IDLE),
    redirectUrl: signal('/'),
    initialEmail: signal<string | undefined>(undefined),
    error: signal<Error | undefined>(undefined),
  };
};

export const LOGIN_STATE_TOKEN = new InjectionToken<LoginState>('LOGIN_STATE_TOKEN');

export const loginStateProvider = {
  provide: LOGIN_STATE_TOKEN,
  useFactory: createLoginState,
};
