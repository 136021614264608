import { Component, effect, Inject, OnDestroy, OnInit } from '@angular/core';
import { SpinnerService } from '@services/spinner.service';
import {
  AccountInactiveError,
  AccountLockedError,
  AccountSuspendedError,
  ConnectionError,
  SSOAccountNotFound,
  SSOError,
  SSOLoginError,
  SuperAccountPendingError,
  TechnicalUserCannotLoginToUIError,
  WrongEmailOrPasswordError,
} from '@repository/authentication/models/errors.model';
import { MessageService } from '@services/message.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { TimeoutError } from 'rxjs';
import { LOGIN_STATE_TOKEN } from './login-state.provider';
import { LoginServiceState, LoginState } from './login-state.model';
import { LoginBusinessService } from './business-service/login-business.service';
import { SSOLoginBusinessService } from './business-service/sso-login-business';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss',
  providers: [LoginBusinessService, SSOLoginBusinessService],
})
export class LoginComponent implements OnInit, OnDestroy {
  public static readonly SSO_LOGIN_PATH = 'sso';
  public static readonly SSO_CALLBACK_PATH = 'callback';

  public constructor(
    public spinnerService: SpinnerService,
    public messageService: MessageService,
    @Inject(LOGIN_STATE_TOKEN) public loginState: LoginState,
    public translateService: TranslateService,
    public loginBusinessService: LoginBusinessService,
    public router: Router,
  ) {
    this.setSpinner();
    this.setErrorHandler();
    this.onLoginSuccessHandler();
  }

  public ngOnInit(): void {
    this.spinnerService.hide();
    this.loginBusinessService.init();
  }

  public ngOnDestroy(): void {
    this.spinnerService.hide();
  }

  private setErrorHandler(): void {
    effect(() => {
      if (this.loginState.state() !== LoginServiceState.ERROR) {
        return;
      }
      const error = this.loginState.error();
      if (error instanceof SuperAccountPendingError) {
        this.messageService.showWarningMessage('authentication.super_user_account_pending');
      } else if (error instanceof WrongEmailOrPasswordError) {
        this.messageService.showErrorMessage('authentication.wrong_email_or_password');
      } else if (error instanceof AccountLockedError) {
        this.messageService.showErrorMessage('authentication.account_locked');
      } else if (error instanceof AccountInactiveError) {
        this.messageService.showErrorMessage('authentication.account_inactive');
      } else if (error instanceof AccountSuspendedError) {
        this.messageService.showErrorMessage('authentication.account_suspended');
      } else if (error instanceof TechnicalUserCannotLoginToUIError) {
        this.messageService.showErrorMessage('authentication.technical_user_cannot_login_to_ui');
      } else if (error instanceof ConnectionError || error instanceof TimeoutError) {
        this.messageService.showErrorMessage('general.backend_not_running');
      } else if (error instanceof SSOError) {
        this.messageService.showErrorMessage('authentication.sso_error');
      } else if (error instanceof SSOAccountNotFound) {
        this.messageService.showErrorMessage('authentication.sso_user_not_found');
      } else if (error instanceof SSOLoginError) {
        this.messageService.showErrorMessage('authentication.sso_login_error');
      }
    });
  }

  private onLoginSuccessHandler(): void {
    effect(() => {
      if (this.loginState.state() !== LoginServiceState.SUCCESS) {
        return;
      }
      void this.router.navigateByUrl(this.loginState.redirectUrl());
    });
  }

  private setSpinner(): void {
    effect(() => {
      if (this.loginState.state() === LoginServiceState.LOADING) {
        this.spinnerService.show();
      } else {
        this.spinnerService.hide();
      }
    });
  }
}
